$primaryColor: #2957a4;
$secondaryColor: #f58634;
$whitebackgroundColor: #ffffff;
$black: #000000;
$red: #d14444;
$grey: #6e6560;
$grey900: #231f20;
$darkgrey: #19110b;

$white: #fff;
$primary: #2957a4;
$secondary: #f58634;

$theme-colors: (
    "primary": $primaryColor,
    "secondary": $secondaryColor,
);
