.advertisement {
   padding: 0;
   margin: 0;
}

.brands {
   background-color: darken($whitebackgroundColor, 5%);
   padding: clamp(1.875rem, 5vw, 3.75rem) 0;
}

.sales-promotion {
   margin-bottom: clamp(1.875rem, 7.5vw, 2.75rem);
}