.breadcrumbs-wrapper {
	background-color: darken($white, 4.5%);
	margin-bottom: clamp(1.25rem, 5vw, 3rem);
	position: sticky;
	top: 0;
	z-index: 5;
	transition: all 0.25s ease;

	.breadcrumb {
		padding: 10px 0;
		background: transparent;

		li {
			@include pxtorem(12);

			&.active {
				overflow: hidden;
				text-overflow: ellipsis;
				-webkit-line-clamp: 1;
				display: -webkit-box;
				-webkit-box-orient: vertical;
			}
		}

		@media (min-width: 576px) {
			padding: 10px 20px;
		}
	}
}

.is-sticky {
	& + .breadcrumbs-wrapper {
		position: sticky;
		top: 148px;

		@media (min-width: 576px) {
			top: 108px;
		}
	}
}