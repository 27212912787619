.site-wrapper {
    min-height: 100%;
    position: relative;
}

a {
    text-decoration: none;
    &:hover,
    &:focus {
        text-decoration: none;
    }
}