.cart {
    &--count {
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        right: -6px;
        bottom: -6px;
        background: $white;
        font-size: 0.75rem;
        line-height: 1.25rem;
    }

    &--title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 1.25rem;
        // box-shadow: 0 0.25rem 0.75rem rgba($darkgrey, 0.1);
        border-bottom: 1px solid rgba($darkgrey, 0.1);
        z-index: 10;

        span {
            margin-right: 0.5rem;
        }

        i {
            cursor: pointer;
            transition: all 0.25s ease;

            &:hover {
                opacity: 0.8;
            }
        }
    }

    &--content {
        margin: 3.2rem 0 !important;
        height: 73vh !important;
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
        z-index: 1;
    }

    &--products {
        &-list-item {
            padding: 1rem 0;
            border-bottom: 1px solid #d5d5d5;
        }

        &--thumb {
            flex: 0 0 4rem;
            height: 4rem;
            background: $white;
            overflow: hidden;
            margin-right: 1.5rem;
            margin-top: 8px;
        }

        &--detail {
            flex: 1;

            button {
                background: transparent;
                border: 0;
                min-height: 1rem;
                align-items: center;

                .ic--remove {
                    height: 2px;
                }
            }
        }

        &--qty {
            input {
                width: 3rem;
                border: 0;
                text-align: center;
            }
        }

        &--minus-button {
            i {
                font-size: 1.5rem !important;
            }
        }
    }

    &--total {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0.75rem 1rem;
        box-shadow: 0 -0.25rem 0.75rem rgba($darkgrey, 0.1);
        z-index: 10;
        background: $white;

        .btn {
            display: block;
            margin: 0.625rem 0 6px;
        }
    }
}
