$fontSize   : 16;
$lineGap    : 1.5;
$fontFamily : "Open Sans", sans-serif;
$headerFont : "Open Sans", sans-serif;
$headerSizes: (
	h1: 32,
	h2: 28,
	h3: 24,
	h4: 20,
	h5: 18,
	h6: 16,
);