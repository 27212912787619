.price-range-block {
    input[type="range"] {
        &.multirange {
            &.ghost {
                background: var(--track-background);
                --track-background: linear-gradient(
                    to right,
                    transparent var(--low),
                    var(--range-color) 0,
                    var(--range-color) var(--high),
                    transparent 0
                    )
                no-repeat 0 45% / 100% 40%;
                --range-color: $primary;
            }
        }
    }
}

.heading {
    @include pxtorem(30);

    @media (min-width: 992px) {
        @include pxtorem(60);  
    }

    @media (min-width: 992px) and (max-width: 1200px) {
        @include pxtorem(48);
    }

    &--primary {
        color: $primary;
    }

    &--secondary {
        color: $secondary; 
    }
}

.seller-landing-pg {
    position: relative;
    background: url('../../images/frontend_images/pattern.png') no-repeat top right;
    background-size: 600px;
}

.become-seller {
    position: relative;
    padding: clamp(1.25rem, 10vw, 3.75rem) 0;

    &--container {
        position: relative;

        .heading {
            &--secondary {
                @media (min-width: 992px) {
                    padding-left: 18px;
                }
            }

            &--primary {
                margin-top: -25px;

                @media (min-width: 992px) {
                    position: absolute;
                    top: 44px;
                    left: 12px;
                    margin: 0;
                }
            }
        }
    }

    &--content {
        margin: auto 0;
        text-align: start;

        p {
            max-width: 500px;
            @include pxtorem(16);

            @media (min-width: 992px) {
                @include pxtorem(22);
            }

            span {
                color: $secondary;
            }
        }

        & > a {
            font-size: 18px;
            margin-top: 50px;
        }

        .btn-secondary {
            border-radius: 1.25rem;
            padding: 0.4rem 4.15rem;
            @include pxtorem(20);
        }
    }
}

.sell-more {
    position: relative;

    img {
        width: 100%;
        height: inherit;
        object-fit: cover;
    }

    &--container {
        padding: clamp(1.25rem, 10vw, 3.75rem) 0;

        @media (min-width: 768px) {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
    }

    &--content {
        position: relative;

        .heading--primary {
            padding-left: 0;
        }

        .heading--secondary {
            margin-top: -1.5rem;

            @media (min-width: 768px) {
                margin-top: 0;
                position: absolute;
                top: 20px;
                right: 0;
            }

            @media (min-width: 992px) {
                top: 40px;
            }
        }
    }
}

.grow-your-business {
    padding: clamp(1.25rem, 10vw, 3.75rem) 0;
    position: relative;

    .join-now {
        position: relative;

        .heading--primary {
            padding-left: 0;
        }

        .heading--secondary {
            margin-top: -1.5rem;

            @media (min-width: 992px) {
                margin-top: 0;
                position: absolute;
                top: 38px;
                left: 23px;
            }
        }

        p {
            margin: clamp(1.25rem, 5vw, 3.75rem) 0;
            @include pxtorem(16);

            @media (min-width: 992px) {
                @include pxtorem(22);
            }
        }
    }

    .registering-steps {
        position: relative;
        padding: 2rem 0 1.25rem;
        border: 1px solid $primary;
        border-radius: 1rem;
        margin-top: 3rem;

        .special-head {
            color: $primary;
            background: $white;
            padding: 0.25rem 0.5rem;
            width: max-content;
            position: absolute;
            top: -1.5rem;
            left: 50%;
            transform: translateX(-50%);
            @include pxtorem(16);
        }

        h3 {
            @include pxtorem(16);
            color: $grey;
        }

        h4 {
            @include pxtorem(16);
            color: $primary;
        }

        .btn-secondary {
            border-radius: 1.25rem;
            padding: 0.4rem 4.15rem;
            font-size: 20px;
        }

        .steps {
            padding: 1.25rem;
            height: 100%;
            background-image: linear-gradient(135deg, $white, rgba($white, 0.5), darken($white, 12.5%));
            border: 1px solid $primary;
            border-radius: 1rem;
            ul {
                padding: 20px;

                li {
                    list-style: square;
                }
            }
        }

        @media (min-width: 992px) {
            border-radius: 3rem;
            margin-top: 0;

            .special-head {
                @include pxtorem(28);
            }

            h3 {
                @include pxtorem(24);
            }

            h4 {
                @include pxtorem(20);
            }

            .steps {
                padding: 2rem;
            }
        }
    }
}

.already-seller {
    max-width: 225px;
}