.popular {
    $this: &;

    &-categories {
        background-color: darken($whitebackgroundColor, 5%);
        .list-items-row {
            padding: ($gutter * 4px) 0;
        }

        .d-flex {
            gap: 30px;
            flex-wrap: wrap;
        }
    }

    &-devices {
        flex: 0 1 88px;
        border-radius: 4px;

        a {
            &:hover {
                .icon {
                    figure {
                        transform: scale(1);
                    }
                }
            }
        }
        .icon {
            width: 100%;
            padding-bottom: 100%;
            height: 0;
            background-color: $whitebackgroundColor;
            position: relative;

            &-name {
                text-align: center;
                font-size: 14px;
                padding-top: 2px;
                color: darken($grey, 26.5%);
            }

            figure {
                position: absolute;
                inset: 0;
                width: 70%;
                height: 70%;
                margin: auto;
                transform: scale(0.9);
                transform-style: preserve-3d;
                transition: all 0.2s ease;
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: contain;
            }
        }
    }

    &-icon {
        padding-bottom: 40px;
    }
}
