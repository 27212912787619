.sidebar {
	padding: 1.75em;
	position: relative;
	min-height: 100%;

	&-wrapper {
		background-color: $whitebackgroundColor;
		max-width: 23.75rem;
		height: 100%;
		overflow: auto;
		position: fixed;
		
		a {
			color: $primaryColor;
		}	
	}

	h2 {
		padding: 0.25rem 0;
	}

	.social-login {
		i {
			@include pxtorem(30);
		}

		img {
			width: 2rem;
			margin: 0.25rem 0;
		}
	}

	.register-user,
	.back-to-login {
	    position: absolute;
	    bottom: 10px;
	    width: 100%;
	    left: 0;
	}

	.drag-drop-image {
	    margin-top: 1rem;
	    background-color: darken($whitebackgroundColor, 4.9%) !important;
	    border: 1px solid darken($whitebackgroundColor, 23%) !important;
	    color: #495057;
	    text-align: center;
	    width: 100%;
	    height: 4.0625rem;
	}
}