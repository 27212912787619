// Vendor Registration Page Styles
.registration {
    .textbox {
        margin-bottom: 0.75rem;
    }

    &-form-section {
        padding: 2.5rem 0 0;

        h5 {
            margin-bottom: 1rem;

            span {
                position: relative;

                &::after {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    // content: "";
                    width: 100%;
                    height: 1px;
                    background: $grey;
                    opacity: 0.15;
                }
            }
        }
    }

    &-file-upload {
        .file-input {
            .kv-fileinput-caption {
                display: none;
            }

            .input-group-btn {
                margin-top: 1rem;
            }

            .btn {
                font-size: 0.875rem;
            }

            .fileinput-remove {
                opacity: 0.75;
                cursor: pointer;
            }

            .fileinput-cancel {
                opacity: 0.75;
            }

            .fileinput-upload-button {
                background: $secondaryColor;
                border: 0;
                color: $white;

                &:hover {
                    background: darken($secondaryColor, 5%);
                }
            }

            &-new {
                .kv-fileinput-caption {
                    height: 5rem;
                    display: block;

                    &::after {
                        content: "Click here to upload files.";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .fileinput-cancel {
                    display: none;
                }

                .btn-file {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
            }
        }

        .input-group {
            width: 100%;

            &-btn {
                width: inherit;
            }
        }

        .file-preview-frame {
            box-shadow: none;
            border: 0 none;
            cursor: pointer;

            &:hover {
                box-shadow: 0 2px 10px rgba($darkgrey, 0.1);
            }
        }
    }
}

.vendor-registration {
    &-info {
        padding: 1rem;
        background: $white;
        border-radius: 0.5rem;
        box-shadow: 0 10px 48px rgba($black, 0.075);

        h2 {
            @include pxtorem(34);
            color: $secondaryColor;
        }

        p {
            @include pxtorem(20);
        }

        button {
            padding: 1rem 2rem;
            border-radius: 3rem;
            @include pxtorem(16);
            box-shadow: 0 4px 10px rgba($black, 0.1);
            margin: 3rem 0;
        }
    }

    h1 {
        @include pxtorem(48);

        &~p {
            @include pxtorem(24);
            margin-bottom: 3rem;
        }
    }
}

.user-registration {
    padding-top: 0.75rem;

    .form {
        display: none;
    }

    .form.active {
        display: block
    }
}

.password-help-text {
    li {
        list-style-type: circle;
        font-size: 0.813rem;
    }
}
