%headerText {
    line-height: 1.43;
    color: $darkgrey;
    font-family: $headerFont;
    font-weight: 700;
}

@for $h from 1 through 6 {
    h#{$h} {
        @extend %headerText;
        @include pxtorem(map-get($headerSizes, h#{$h}));
    }
}

.text-primary {
    color: $primary !important;
}

.small {
	@include pxtorem(14);
}

.font-light {
    font-weight: 300;
}

.font-extra-light {
    font-weight: 200;
}
