//================ Header Styles ================//
.hero {
    &-field {
        a {
            color: $grey;

            i {
                font-size: 20px;

                .la-instagram {
                    font-size: 22px;
                }
            }

            &:hover {
                color: $primaryColor;
                font-size: bold;
            }
        }

        .underline-on-hover:hover {
            text-decoration: underline !important;
            color: $primaryColor;
            font-size: bold;
        }
    }
}

.top-section {
    position: sticky;
    top: 0;
    z-index: 10;

    &.is-sticky {
        header {
            .logo-img {
                width: 7.5rem;
            }
        }
    }
}

%iconMenu {
    text-align: center;
    padding: 0.75rem;
    color: $grey;
    transition: all 0.25s ease;

    i {
        @include pxtorem(28);
        transition: all 0.25s ease;
    }

    &:hover {
        color: $secondaryColor;
    }

    .is-sticky & {
        padding: 0.25rem 0.75rem;

        i {
            @include pxtorem(24);
        }
    }
}

header {
    .logo-img {
        width: 10rem;
        margin: 0;
        transition: width 0.25s ease;
    }

    .coupon {
        @extend %iconMenu;
    }

    .profile {
        @extend %iconMenu;
    }

    .cart {
        @extend %iconMenu;
        position: relative;

        &-count {
            background: #f58634 !important;
            border-radius: 50% !important;
            width: 20px;
            height: 20px;
            font-size: 13px !important;
            position: absolute !important;
            color: $whitebackgroundColor !important;
            display: flex;
            justify-content: center;
            top: 4px;
            right: 1px;
            padding: 0 !important;
        }
    }
}

//================ Categories Menu Styles ================//

%navlinks {
    @include pxtorem(14);
    color: $whitebackgroundColor;
    padding: 0.75rem 1.25rem;
    text-decoration: none;
    transition: all 0.25s ease;

    &:hover {
        background: rgba($black, 0.15);
        color: $whitebackgroundColor;
    }

    .is-sticky & {
        padding: 0.5rem 1.25rem;
    }
}

.menu-bar {
    background: $primaryColor;

    .navbar {
        position: static;

        a {
            float: left;
            font-size: 16px;
            color: white;
            text-align: center;
            padding: 14px 16px;
            text-decoration: none;
        }

        &-nav {
            .nav-item {
                &:hover {
                    .dropdown-content {
                        display: block;
                    }
                }
            }

            .nav-link {
                @extend %navlinks;

                &.active {
                    color: $whitebackgroundColor;
                    background: rgba($black, 0.15);
                }
            }
        }

        .test {
            & > *:nth-last-child(1) {
                .dropdown-content--categories {
                    margin-bottom: 250px;
                }
            }

            div {
                .head-to-link {
                    font-size: 1rem;
                    font-weight: 700;
                    padding-bottom: 0.25rem;
                    background-color: transparent;
                    cursor: pointer;
                    h6 {
                        &:hover {
                            color: $primaryColor !important;
                        }
                    }
                }
            }
        }

        button {
            @extend %navlinks;
            border: 0;
        }
    }

    i {
        margin-right: 0.5rem;
    }

    .seller-option {
        background: $secondaryColor;
        width: 100%;

        @media (min-width: 992px) {
            width: auto;
        }
    }

    /* The dropdown container */
    .dropdown {
        float: left;
        overflow: hidden;

        &.dropbtn {
            font-size: 16px;
            border: none;
            outline: none;
            color: white;
            padding: 14px 16px;
            background-color: inherit;
            font: inherit;
            /* Important for vertical align on mobile phones */
            margin: 0;
            /* Important for vertical align on mobile phones */
        }

        &-content {
            display: none;
            position: absolute;
            background-color: #f4f4f4;
            width: 100%;
            left: 0;
            top: 100%;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            overflow: auto;
            max-height: 700px;
        }

        &-content--categories {
            position: relative;
        }

        &-content--subcategories {
            width: 200px;
            height: auto;
            background-color: #f4f4f4;
            position: absolute;
            left: 100%;
            top: 0;
            z-index: 1;
            display: none;
            padding: 0.375rem 0;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        }
    }

    .dropright {
        position: relative;

        a {
            i {
                visibility: hidden;
            }

            &:hover {
                i {
                    visibility: visible;
                }
            }
        }

        &:hover {
            .dropdown-content--subcategories {
                display: block;
            }
            .dropbtn {
                background: #d3d3d3;
            }
            i {
                visibility: visible;
            }
        }
    }

    /* Create three equal columns that floats next to each other */
    .column {
        padding: 16px;
        height: auto;

        a {
            float: none;
            color: black;
            padding: 4px;
            text-decoration: none;
            display: block;
            text-align: left;
            font-size: 0.875rem;

            &:hover {
                background: #d3d3d3;
            }
        }
    }
}

//================ Footer Menu Styles ================//
.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.upper_footer {
    background-color: $primary;
    color: $white;

    .links {
        padding: 60px 0;
        display: flex;
        justify-content: space-evenly;
    }

    ul {
        margin-bottom: 0;
    }

    li {
        margin-bottom: 1rem;
    }

    a {
        color: rgba($white, 0.8);
        transition: all 0.25s ease-in-out;

        &:hover {
            color: rgba($white, 0.95);
        }
    }

    .social-menu {
        a {
            @include pxtorem(18);
        }

        i {
            @include pxtorem(18);
        }

        .modal-content {
            figure {
                margin-bottom: 0;
            }
        }
    }

    .payment-entities {
        .logo-image {
            width: 120px;
            height: 66px;
            margin-bottom: 0;
            background: $white;
            transition: all 0.25s ease-in-out;
            display: flex;
            align-items: center;
            padding: 0 10px;
            border-radius: 10px;

            &:hover {
                background: rgba($white, 0.65);
            }
        }

        & > div {
            margin: 0 10px 10px 0;
        }

        .cash-on-delivery {
            figure {
                overflow: hidden;
                padding: 0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

.lower_footer {
    background-color: darken($primary, 15%);
    color: rgba($white, 0.75);
    padding: 0.5rem;
    @include pxtorem(12);
}

/* // for autocomplete jquery */

.ui-front {
    position: relative;
}

.ui-menu {
    position: absolute;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.21);
}

.ui-menu-item a {
    display: block;
}

.ui-widget {
    font-size: 15px;
    border: none !important;
}

.ui-widget.ui-widget-content {
    max-width: 100%;
    top: 0;
}

.ui-menu-item .ui-menu-item-wrapper.ui-state-active {
    background: transparent;
    color: #2957a4 !important;
    border-color: transparent;
}

.ui-menu .ui-menu-item-wrapper {
    padding-left: 9px;
}
