.list-items-row {
    padding: clamp(1.875rem, 5vw, 3.75rem) 0;

    h2 {
        margin-bottom: 0;

        &+p {
            margin-bottom: 1.5rem;
        }
    }

    &.just-for-you-products {
        margin-bottom: clamp(1.875rem, 7.5vw, 2.75rem);

        .d-grid {
            /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
            grid-template-columns: repeat(2, minmax(170px, 1fr));
            grid-gap: 1.25rem;

            @media (min-width: 992px) {
                grid-template-columns: repeat(3, minmax(220px, 1fr));
            }

            @media (min-width: 1200px) {
                grid-template-columns: repeat(4, minmax(220px, 1fr));
            }
        }
    }
}

.week-product {
    flex-wrap: wrap;
}

.weekly-deal {
    position: relative;

    .view-more {
        color: $darkgrey;

    }

    .text-primary {
        color: $primary;
    }

    .owl-nav {
        position: absolute;
        top: -3.375rem;
        right: 1rem;
    }

    .owl-dots {
        display: none;
    }

    &+.advertisement-item {
        margin-top: clamp(1.875rem, 5vw, 3.75rem);
    }
}

.top-offer-on {
    background: $grey900;

    .product {
        border: 1px solid #f4f4f4 !important;

    }
}

.product {
    $this: &;

    border-radius: 0.25rem;
    border: 1px solid darken($white, 7.5%);

    .owl-item & {
        margin-bottom: 0;
    }

    &-image {
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        background: darken($white, 4.5%);
        position: relative;
        overflow: hidden;

        figure {
            position: absolute;
            inset: 0;
            width: 85%;
            height: 85%;
            margin: auto;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                position: absolute;
                transform: scale(1);
                transition: transform 0.25s ease;
                color: $grey;
            }
        }
    }

    &-actions {
        opacity: 0;
        transition: all 0.25s ease-in-out;
        display: none;

        a {
            background-color: $white;
            border-radius: 50%;
            font-size: 1.875rem;
            position: absolute;
            top: 10px;
            right: 10px;
            color: $darkgrey;
            box-shadow: 0 4px 10px rgba($black, 0.15);
            border: 1px solid rgba($black, 0.05);
            padding: 0 0.4rem;
            display: inline-block;
        }
    }

    &-content {
        background: $white;
        padding: 1rem;

    }

    &-name {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        margin-bottom: 0;
        word-break: break-word;
    }

    &-ratings {
        color: $secondary;
        display: none;
    }

    &-price {
        color: $darkgrey;
        margin: 0 0 0.5rem 0;
        font-weight: 600;
        @include pxtorem(18);
    }

    &-previous-price {
        color: $grey;
        font-weight: 400;
        @include pxtorem(12);

        span {
            text-decoration: line-through;
            // display: none;

            &:last-of-type {
                text-decoration: none;

                &:after {
                    content: "OFF";
                }
            }
        }
    }

    &:hover {
        #{$this}-actions {
            opacity: 1;
        }

        #{$this}-image {
            figure {
                img {
                    transform: scale(1.1);
                }
            }
        }
    }

    ////======================================================= PRODUCT DETAIL STYLES

    &-detail-wrapper {
        background: $white;
        border-radius: 0.25rem;

        >.d-grid {
            grid-template-columns: 1fr;
            grid-gap: 1rem;
            align-items: start;
            overflow: hidden;
        }

        #{$this}-previous-price {
            @include pxtorem(14);
            font-weight: 500;
        }

        @media (min-width: 991px) {
            padding: 1.25rem;

            &>.d-grid {
                grid-template-columns: 400px 1fr;
                grid-gap: 5rem;
            }
        }

        @media (min-width: 1400px) {
            padding: 1.25rem;

            &>.d-grid {
                grid-template-columns: 650px 1fr;
                grid-gap: 5rem;
            }
        }
    }

    &-images-carousel {
        height: clamp(20.4375rem, 80vw, 37.5rem);
        background: darken($white, 4.5%);
        border: 1px solid darken($white, 7.5%);
        margin-bottom: 0.5rem;
        border-radius: 0.25rem;

        & *:not(.owl-dots) {
            height: 100%;
        }

        @media (min-width: 991px) {
            padding: 1.25rem;
        }
    }

    &-info {
        h2 {
            margin: 60px 0 33px 0;
        }

        .product-image-thumbs {
            gap: 16px;

            a {
                background: darken($white, 4.5%);
                border: 1px solid darken($white, 7.5%);
                padding: 1.2rem;
                border-radius: 0.25rem;

                img {
                    width: 3rem;
                    height: auto;
                }
            }
        }
    }

    &-quantity {
        margin: 2.5rem 0;

        p {
            margin-right: 1rem;
            font-size: 1rem;
            font-weight: bold;
        }

        input {
            padding: 0.25rem;
            border: 1px solid rgba($grey, 0.15);
            border-radius: 0.25rem;
        }
    }

    &-list {
        h3 {
            margin-bottom: 32px;
        }
    }

    &-seller-info {
        display: none;
        padding: 1.25rem;
        background: rgba($grey, 0.05);
        grid-template-columns: 1fr !important;
        grid-gap: 0.5rem !important;

        p {
            margin: 0;
        }
    }

    &-detail-content {
        padding: 1.25rem 0;
        margin: 2rem 0 0;
        // border-radius: 0.25rem;
        background: $white;
        word-break: break-all;
        border-top: 1px solid rgba($grey, 0.25);

        h5 {
            font-family: $fontFamily;
        }

        &>div {
            * {
                font-family: $fontFamily !important;
                color: $grey !important;
                font-size: 0.875rem !important;
            }
        }
    }

    &-detail-meta {
        margin-top: 1rem;

        .product-name {
            -webkit-line-clamp: 1000;
        }

        .product-price {
            font-size: 1.4rem !important;
            margin: 1.4rem 0;
        }

        .product-ratings {
            margin-top: 1rem;
        }

        .short-summary {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            text-transform: capitalize;
            margin-bottom: 0.25rem;
            word-break: break-word;
            font-weight: 400;

            & * {
                font-family: $fontFamily !important;
                font-weight: 400 !important;
                font-size: inherit !important;
                color: currentColor !important;
                margin: 0 !important;
                padding: 0 !important;
                display: contents;
            }

            ul {
                padding: 0 !important;
                font-size: normal;
            }
        }

        a:not(.btn) {
            color: $primary;
            text-decoration: underline;
        }
    }
}

.related-products {
    background: $grey900;

    h2 {
        margin-bottom: 1.25rem;
    }

    .d-grid {
        grid-template-columns: repeat(2, minmax(170px, 1fr));
        grid-gap: 1.25rem;

        @media (min-width: 992px) {
            grid-template-columns: repeat(3, minmax(220px, 1fr));
        }

        @media (min-width: 1200px) {
            grid-template-columns: repeat(4, minmax(220px, 1fr));
        }
    }
}

.circle-background {
    position: fixed;
    width: 60px;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    background-color: $white;
    padding: 10px 10px;

    i {
        width: 80xp;
        font-size: 40px;
    }
}

.input {
    text-align: center;
}

.select-product {
    background-color: darken($white, 4.5%);
    padding: 1.5rem;
    border-radius: 4px;
}

#slider-range {
    margin-top: 15px;
    height: 4px;
    background-color: lighten($grey, 26.5%);

    .ui-slider {
        &-range {
            background-color: $primary;
            height: 4px;
        }

        &-handle {
            border-radius: 50%;
            height: 18px;
            width: 18px;
            background-color: $primary;
            outline: none;
            top: -0.5em;
            border: 0;
            cursor: pointer;
        }
    }
}

#amount {
    background-color: darken($white, 4.5%);
    text-align: center;
    color: $secondary;
    outline: none;
    display: block;
    overflow: hidden;
    width: 100%;
}

.category-products-list {
    background: $white;
    border-radius: 4px;
    grid-template-columns: repeat(2, minmax(170px, 1fr));
    grid-gap: 1rem;

    @media (min-width: 768px) {
        grid-template-columns: repeat(3, minmax(170px, 1fr));
        grid-gap: 1.5rem;
    }
}

.magnifier {
    background: darken($white, 4.5%);
    border: 1px solid darken($white, 7.5%) !important;
    border-radius: 0.25rem;
}
