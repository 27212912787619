li {
    list-style: none;
}

.product-detail-content,
.return-and-refunds {
    li {
        list-style: circle;

    }
}
