img {
    max-width: 100%;
    position: relative;
    image-rendering: -webkit-optimize-contrast;
    object-fit: contain;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        content: attr(alt);
        width: 100%;
        height: 100%;
        background: darken($whitebackgroundColor, 4.5%);
        background-size: contain;
        padding: 0.125rem;
        @include pxtorem(12);
    }
}