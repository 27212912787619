.modal {
	z-index: 3100;

	&-backdrop {
		z-index: 3050;
	}

	&-title {
		display: none;
	}
}

.bfd-dropfield-inner {
    text-align: center;
    padding: 100px !important;
    height: auto !important;
}
