.login-landing {
	&--content {
		position: relative;

		@media (min-width: 768px) {
			&::after {
				content: "";
				position: absolute;
				top: 10%;
				right: -20%;
				height: 80%;
				width: 1px;
				background: $grey;
				opacity: 0.2;
			}
		}

		.heading--secondary {
			margin-top: -1.5rem;

			@media (min-width: 992px) {
				margin-top: 0;
				position: absolute;
				top: 65px;
				left: 35px;
				z-index: 2;
			}
		}
	}

	.register-user,
	.back-to-login {
		position: static;
	}
}