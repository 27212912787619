.slider {
    img {
        height: 100%;
        object-fit: cover;
    }
    .owl-item {
        height: auto;
        overflow: hidden;
    }
    .owl-nav {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 1rem;
        button {
            span {
                @include pxtorem(60);
            }
        }
    }
}

.owl-dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    .owl-dot {
        width: 10px;
        transition: 0.25s ease-in-out;
        &+.owl-dot {
            margin-left: 4px;
        }
        &.active {
            width: 25px;
            span {
                background: $primaryColor !important;
            }
        }
        span {
            width: 100% !important;
        }
    }
}
