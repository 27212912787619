#cookit {
  top: 0;
  bottom: auto;
  padding: 30px 16px;
  position: fixed;
  width: 100%;
  display: flex;
  z-index: 999;
  &.hidden {
    display: none;
  }

  // =================================== COOKIT CONTAINER STYLES ===================================
  &-container {
    margin: auto;
    width: 1200px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    h4 {
      line-height: 1.2;
      font-size: 20px;
      line-height: 2;
      color: $whitebackgroundColor !important;
    }
    p {
      line-height: 1.4rem;
      font-size: 14px;
      font-weight: 400;
    }
    a {
      text-decoration: none;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  // =================================== COOKIT BUTTON STYLES ===================================
  &-button {
    border-radius: 4px;
    width: 88px;
    height: 44px;
    background-color: $primaryColor;
    color: $whitebackgroundColor !important;
    text-align: center;
    line-height: 2.2;
    padding: 0.5rem 1rem;
    margin-left: auto;
  }
}
