html {
    font-size: $fontSize * 1px;
    height: 100%;
    scroll-behavior: smooth;
}

body {
    color: $grey;
    font-family: $fontFamily;
    font-size: 0.875rem;
    height: 100%;
    line-height: $lineGap;
    text-rendering: optimizeLegibility;
}
