.pre-line {
    white-space: pre-line;
}

.bg-primary {
    background-color: $primaryColor;
}

.sticky-filter {
    position: sticky;
    top: 80px;
    z-index: 2;

    .loadMore-btn {
        color: $primaryColor;
    }

    .not-shown {
        display: none;
    }

    .shown {
        display: block;
    }
}

.filter-button-width {
    background-color: darken($whitebackgroundColor, 4.9%);
    width: 100%;
    padding: 10px;
    font-size: 18px;
    color: $primaryColor !important;
    font-weight: 700;

    p {
        float: left;
    }

    i {
        float: right;
        font-size: 14px;
    }
}

.quantity {
    width: 50px;
    height: auto;
}

.quantity-button {
    color: $primaryColor;
    padding: 5px;
    border: none;
    background-color: #f3f3f3;
}

.d-grid {
    display: grid;
}
