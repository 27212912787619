.form {
	&-label {
		@include pxtorem(14);
		margin-bottom: 0.25rem;
	}

	&-control {
		height: auto;
		padding: 0.5rem 0.75rem;
		border: 1px solid darken($white, 7.5%);
		background: darken($white, 3%);
		line-height: 1.43;
		font-size: 0.875rem;
	}
}

.search-form {
	position: relative;
	background: darken($white, 4.5%);
	border-radius: 4px;
	max-width: 15rem;
	width: 100%;
	max-height: 2.625rem;
	margin: 0 0.75rem;
	border: 1px solid darken($white, 7%);

	input {
		padding-right: 3.5rem;
	}

	button {
		height: 100%;
		padding: 0 1rem;
		position: absolute;
		top: 0;
		right: 0;
		@include pxtorem(20);
	}

	@media (min-width: 992px) {
		max-width: 21.875rem;
	}
}	
