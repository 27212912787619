button {
    outline: none !important;
}

.btn {
    border: 0;
    padding: 0.5rem 1rem;
    @include pxtorem(14);

    &[disabled] {
        pointer-events: none;
    }

    &-primary {
        background: $primaryColor;

        &:hover {
            background: darken($primaryColor, 5%);
        }
    }

    &-secondary {
        background: $secondaryColor;

        &:hover {
            background: darken($secondaryColor, 5%);
        }
    }

    &-default {
        background: $white;
        border: 1px solid darken($white, 3%);
    }
}

.view-details {
    color: $darkgrey;
    padding: 0.5rem 1.5rem;
    display: inline-block;
    border-radius: 3rem;
    box-shadow: 0 4px 10px rgba($black, 0.15);
    border: 1px solid rgba($black, 0.05);
    @include pxtorem(14);
    transition: all 0.25s ease-in-out;

    &:hover {
        background: $darkgrey;
        color: $white;
    }

    @media (min-width: 768px) {
        padding: 0.5rem 2rem;
    }
}
