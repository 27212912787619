.profile-option {
    padding: 20px 30px;
    line-height: 2;
    text-decoration: none;
    position: sticky;
    top: 186px;
    z-index: 1;

    a {
        color: darken($grey, 26.5%);
        font-weight: 600;
    }
}

.profile-info {
    padding: 15px 30px;
    line-height: 2;

    table {
        margin: 12px 0;
    }

    i {
        color: $secondaryColor;
    }

    th {
        color: $grey;
        font-weight: 600;
        line-height: 2;
    }

    td {
        padding-left: 20px;
        color: darken($grey, 26.5%);
        font-weight: 700;
    }

    h6 {
        font-size: 0.875rem;
    }
}

.confirm-address {
    th {
        font-weight: 400 !important;
    }

    td {
        font-weight: 600 !important;
    }
}

.order-table {
    padding: 15px 0;

    p {
        margin-bottom: 0 !important;
        max-width: 280px;
    }

    .my-cart-image {
        img {
            max-width: 100px;
            max-height: 48px;
            margin: 0 !important;
        }
    }



    .my-cart-coupon-field {
        // padding: 0;

        input {
            background-color: darken($whitebackgroundColor, 4.9%);
            margin-top: 3px;
            width: 120px;
            height: auto;
            border: none;

        }
    }

    th {
        color: $grey;
        font-weight: 600;
        border-top: none;
        padding: 5px;
    }

    .form-group {
        input {
            background-color: darken($whitebackgroundColor, 4.9%);
            margin-top: 3px;
            width: 100%;
            height: auto;
            border: none;
        }

        label {
            color: $grey;
            font-weight: 400;
            border-top: none;
        }
    }

    td {
        color: darken($grey, 26.5%);
        font-weight: 400;
        padding: 10px;

        i {
            font-size: 1.25rem;
        }
    }

    .form-check {
        width: 170px;
        height: 170px;

        label {
            position: relative;
            cursor: pointer;

            input {
                position: absolute;
                opacity: 0;

                &:checked+figure {
                    background: darken($whitebackgroundColor, 5%);
                }
            }

            figure {
                margin-bottom: 0;

                img {
                    mix-blend-mode: multiply;
                }
            }
        }
    }
}

.delivery-address {
    font-size: 14px;
    color: $primaryColor;
    padding: 2px;
}

.delivery-border {
    position: relative;
    border: 4px solid lighten($grey, 26.5%);
    border-radius: 2px;
    width: 280px;
    height: auto;
    padding: 15px 15px 20px 15px;

    .delivery-edit {
        position: absolute;
        top: 3%;
        right: 3%;
    }

    th {
        color: $grey;
        font-weight: 400;
        border-top: none;
    }

    td {
        color: darken($grey, 26.5%);
        font-weight: 600;

        a {
            color: $primaryColor;
        }
    }
}

.entity-image {
    width: 132px;
    height: auto;
}
