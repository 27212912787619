.contact-us {
    padding: 60px 0;

    &-items {
        width: 200px;
        height: 200px;
        border-radius: 8px;
        border: 2px solid $primary;
        display: flex;
        align-items: center;
        justify-content: center;


        div {
            text-align: center;
        }



        i {
            color: $secondaryColor;
            font-size: 60px;
            margin-bottom: 10px;
        }

        &:hover {
            transform: scale(1.1);
        }
    }

    .maps {
        border-top: 1px solid $grey;
        margin-top: 60px;
        text-align: center;
        padding-top: 60px;
    }
}
