.coupons-description {
    margin-bottom: 4rem;
    h2 {
        margin-bottom: 1.5rem;
    }
    .d-flex {
        gap: 1.5rem;
    }

    .available-discount-coupons {
        .coupon-detail {
            background: darken($white, 4.5%);
            border-radius: 0.25rem;
            border: 1px solid darken($white, 7.5%);
            width: 440px;
            height: auto;
            .small {
                color: $secondaryColor;
            }
            .coupon-code {
                span {
                    font-weight: bold;
                    color: $darkgrey;
                }
            }
            .coupon-discount-price {
                color: $secondaryColor;
                font-size: 24px;
                font-weight: bold;
            }
            a {
                background: $darkgrey;
                color: white;
            }
            .card-header {
                button {
                    color: $primaryColor;
                    text-decoration: none;
                    i {
                        transition: transform 0.2s ease-in-out;
                        transform: rotate(-180deg);
                    }

                    &.collapsed {
                        i {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
    }
}
