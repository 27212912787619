@media (min-width: 1440px) {
    .container {
        max-width: 1400px;
    }
}

@media (max-width: 1440px) {
    .navbar {
        .dropdown-content {
            max-height: 500px;
        }
    }
}


@media (max-width: 1366px) {
    .navbar {
        .dropdown-content {
            max-height: 400px !important;
        }
    }
}


@media (max-width: 1200px) {
    .payment-entities {
        flex-direction: column;
    }

    .navbar {
        .test {
            &>*:nth-child(4n) {
                .dropdown-content--categories {
                    .dropright {
                        .dropdown-content--subcategories {
                            left: -96%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .breadcrumbs-wrapper {
        top: 126px;
    }

    .jas-qr-code {
        width: 100% !important;
    }

    .navbar {
        .dropdown-content {
            display: none !important;
        }
    }
}

@media (max-width: 768px) {
    header {
        .logo-img {
            max-width: 125px;
        }
    }

    .confirm-address {
        margin-top: 15px;
    }

    footer {
        .upper_footer {
            .links {
                padding: 60px 0 28px;

                ul {
                    text-align: start;
                }
            }
        }
    }

    .contact-us {
        &-field {
            flex-direction: column;

        }

        &-items {
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 576px) {
    header {
        .search-form {
            max-width: 100%;
            order: 3;
            margin: 0 0 8px;

            &+.d-flex {
                margin-left: auto;
            }
        }
    }

    .menu-bar {
        top: 137px;
    }

    .popular-categories {
        .d-flex {
            gap: 16px;
        }
    }

    .popular-devices {
        flex: 0 0 78px;
    }

    .sidebar {
        padding: 20px;
    }




}
