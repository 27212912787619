.alert {
    &-dismissable {
        position: fixed;
        bottom: 5px;
        right: 22px;
        z-index: 10;
        width: 300px;
        height: auto;
    }
}
